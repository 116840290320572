<template>
  <div class="citationPushPreviewHtml">
    <div class="center_2" style="padding-top:20px">
      <iframe id="iframe" style="margin-top:20px; width: 100%;" :src="pageUrl" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'citationPushPreviewHtml',
  components: {},
  props: {},
  data () {
    return {}
  },
  watch: {},
  computed: {
    pageUrl () {
      return window.location.origin + '/das-api/vas/push/citation/preview?pushMagCitationAuthorId=' + this.$route.query.authorId
    }
  },
  mounted () {
    this.setIframeHeight('iframe')
  },
  methods: {
    setIframeHeight (id) {
      let _this = this
      try {
        var iframe = document.getElementById(id)
        if (iframe.attachEvent) {
          iframe.attachEvent('onload', function () {
            iframe.height = iframe.contentWindow.document.documentElement.scrollHeight + 20
          })
          return
        } else {
          iframe.onload = function () {
            iframe.height = iframe.contentDocument.body.scrollHeight + 20
          }
          return
        }
      } catch (e) {
        throw new Error('setIframeHeight Error')
      }
    }
  }
}
</script>

<style lang="scss">

</style>
<style lang="scss" scoped>
.citationPushPreviewHtml {
  background: #fff;
}
</style>
